@import "src/pages/App.scss";

.dashboard-item {
  //min-height: 250px;
  .button-container {
    background-color: $light;
    padding: 5px;
  }

  .dashboard-item-header {

  }

  .btn-group-xs > .btn, .btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
  }
}

.dashboard-item:hover {
  background-color: $light;
  .button-container {
    background-color: darken($light, 5);
  }
}

