@import "src/pages/App";


.AddItemButton {
    padding: 0;
    font-size: 0.8em;
    // transition: 0.15s all ease;
    cursor: pointer;
  &:hover {
    // padding: 16px * 2;
    font-size: 1.2em;
    background-color: $light;
  }
}
