

$dark: #1E5221;
$primary: #1E5221;

body {
  min-height: 100%;
}

.navbar-primary {
  background-color: #1E5221;
  bottom: 0px;
  left: 0px;
  position: absolute;
  top: 51px;
  width: 200px;
  z-index: 8;
  overflow: hidden;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.flex-fill {
  flex:1 1;
}

.flex-grow {
  flex: 1 0 auto;
}

.flex-even {
  flex: 1;
}

@import "~bootstrap/scss/bootstrap";


.sensor-item {
  //height: 100%;

  &.value-critical {
    background-color: lighten($danger, 35);
  }
  &.value-warning {
    background-color: lighten($warning, 35);
  }
  &.value-ok {
    background-color: lighten($success, 35);
  }
  &.value-unknown {
    // background-color: lighten($success, 35);
  }
}


.progress-bar-vertical {
  transform: rotate(-90deg);
  width: 100px;
}


/* CUSTOM WIDTHS */
.w-10, .w-xs-10 { width: 10%!important; }
.w-15, .w-xs-15 { width: 15%!important; }
.w-20, .w-xs-20 { width: 20%!important; }

/* BREAKPOINTS */

/* XS breakpoint */
@media (max-width: 575px) {
  /* CUSTOM WIDTHS */
  .w-xs-10 { width: 10%!important; }
  .w-xs-15 { width: 15%!important; }
  .w-xs-20 { width: 20%!important; }
  .w-xs-50 { width: 50%!important; }
  .w-xs-100 { width: 100%!important; }
}

/* SM breakpoint */
@media (min-width: 576px) {
  /* CUSTOM WIDTHS */
  .w-sm-10 { width: 10%!important; }
  .w-sm-15 { width: 15%!important; }
  .w-sm-20 { width: 20%!important; }
  .w-sm-50 { width: 50%!important; }
  .w-sm-100 { width: 100%!important; }
}

/* MD breakpoint*/
@media (min-width: 768px) {
  /* CUSTOM WIDTHS */
  .w-md-10 { width: 10%!important; }
  .w-md-15 { width: 15%!important; }
  .w-md-20 { width: 20%!important; }
  .w-md-50 { width: 50%!important; }
  .w-md-100 { width: 100%!important; }
}

/* LG breakpoint */
@media (min-width: 992px) {
  /* CUSTOM WIDTHS */
  .w-lg-10 { width: 10%!important; }
  .w-lg-15 { width: 15%!important; }
  .w-lg-20 { width: 20%!important; }
  .w-lg-50 { width: 50%!important; }
  .w-lg-100 { width: 100%!important; }
}
